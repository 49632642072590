body {
  font-family: "Roboto", sans-serif !important;
}
.headertext {
  font-weight: 500;
  color: #1e1e1e;
  font-size: 16px;
}
.appbtn {
  background-color: #06a7e0;
  border: 1px;
  border-radius: 10px;
  width: 167px;
  height: 40px;
}
.appbtntext {
  font-size: 16px;
  color: #ffffff;
}

.appbtn:hover {
  background-color: transparent;
  border: 1px solid #06a7e0;
}
.appbtn:hover .appbtntext {
  color: #06a7e0;
}

.contactbtn {
  border-radius: 10px;
  border: 1px solid #06a7e0;
  padding: 7px 20px;
}
.contactbtn:hover {
  background-color: #06a7e0;
  color: white;
}

.contactbtn:hover .contactbtntext {
  color: white;
}
.contactbtntext {
  font-size: 16px;
  color: #06a7e0;
}

/* HomePage.css */

.homepage-container {
  padding-top: 50px;
  background-image: url("../assests//images/bg.png");
  background-repeat: no-repeat;
}
.homepage-content {
  margin-top: 40px;
  margin-bottom: 40px;
}
.fonttext {
  color: #06a7e0;
  font-weight: 600;
  font-size: 60px;
}
.fonttext1 {
  color: #263238;
  font-weight: 600;
  font-size: 60px;
}
.homepara {
  font-size: 18px;
  color: #818992;
  max-width: 81%;
}
.Homebtn {
  background-color: #727272;
  color: #ffffff;
}

/* FeaturePage.css */

.feature-page {
  position: relative;
  background-image: url("../assests/images/featurebg.png");
  background-size: cover;
  background-position: center;
  padding: 106px 0 110px;
}

.feature-page-content {
  position: relative;
  width: fit-content;
  margin: 0 auto;
}

h1 {
  font-size: 36px;
  margin-bottom: 20px;
}

.iconimg {
  max-width: 100%;
  height: auto;
  right: -15px;
  top: -8px;
  position: absolute;
}
.featurelogotext {
  color: #ffffff;
  font-weight: 700;
  font-size: 48px;
}
.featurepara {
  font-size: 18px;
  color: #ffffff;
  font-weight: 400;
  line-height: 24px;
  opacity: 50%;
}
.arrowImage {
  position: absolute;
  top: 50%;
  left: -42px;
  z-index: 99;
  transform: translateY(-50%);
}
.cardText {
  margin-left: 20px;
  padding: 0;
  margin-bottom: 0;
  z-index: 9;
}
.cardText h4 {
  color: #222;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.cardText p {
  color: #727272;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 24px;
  letter-spacing: 0.32px;
}

.card {
  border-radius: 16px;
  position: relative;
  overflow: hidden;
}
.cardbg {
  position: absolute;
  max-width: 70px;
  right: 0;
  top: 0;
  z-index: 1;
  left: unset;
}

.cardbgactive {
  position: absolute;
  max-width: 70px;
  right: 0;
  top: 0;
  z-index: 1;
  left: unset;
  display: none;
}

.featurecard {
  border-radius: 20px;
  border: 3px solid #f2f2f2;
  background: #fff;
  box-shadow: 0px 4px 5px 0px rgba(0, 0, 0, 0.05);
  margin-bottom: 14px;
}

.featurecard:hover {
  background-color: #7f7f7f;
  border: 3px solid #868686;
}

.featurecard:hover .cardbg {
  display: none;
}

.featurecard:hover .cardbgactive {
  display: block;
  color: #fff;
}

.featurecard:hover .cardText p,
.featurecard:hover .cardText h4 {
  color: white;
}

/* AboutPage.css */

.aboutpage-container {
  background-color: #f8f8f8;
  padding: 106px 0 110px;
}

.abouticon {
  color: var(--Gray-1, #333);
  text-align: center;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: 45px;
}
.abouticon span {
  color: #06a7e0;
  font-size: 40px;
  font-style: normal;
  font-weight: 300;
  line-height: 45px;
}

.abouticonimg {
  max-width: 100%;
  height: auto;
  right: -15px;
  top: -20px;
  position: absolute;
}

.aboutheading {
  color: #7f7f7f;
  font-size: 18px;
  font-weight: 400;
  margin: 20px 0 90px;
}
.aboutpara {
  color: #06a7e0;
  font-size: 26px;
  font-style: normal;
  font-weight: 500;
  line-height: 36px;
  text-transform: capitalize;
}
.aboutparagph {
  color: #555;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  text-transform: capitalize;
}

/* ReviewPage.css */
.reviewpage-container {
  background-color: #fff;
  padding: 106px 0 110px;
}

.reviewicon {
  color: var(--Gray-1, #333);
  text-align: center;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: 45px;
}
.reviewicon span {
  color: #06a7e0;
  font-size: 40px;
  font-style: normal;
  font-weight: 300;
  line-height: 45px;
}
.reviewiconimg {
  max-width: 100%;
  height: auto;
  right: -15px;
  top: -16px;
  position: absolute;
}

.reviewpage-container .owl-carousel .owl-stage {
  display: flex;
}

.reviewpage-container .owl-carousel .owl-stage .owl-item .item {
  height: 100%;
}

.usercard {
  border-radius: 20px;
  border: 1px solid rgba(6, 167, 224, 0.09);
  background: rgba(6, 167, 224, 0.05);
  height: 100%;
}

.usercard .card-header {
  border-radius: 20px 20px 0px 0px;
  border-bottom: 1px solid rgba(6, 167, 224, 0.09);
  background: #fff;
  padding: 15px 20px;
}

.usercard .card-header h5 {
  color: #222;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding-left: 25px;
  padding-top: 6px;
}
.usercard .card-body {
  padding: 33px 23px 40px 50px;
  position: relative;
}

.usercard .card-body .card-text {
  color: #222;
  font-size: 14px;
  font-weight: 300;
  line-height: 24px;
  letter-spacing: 0.32px;
  margin-bottom: 0;
  position: relative;
}

.usercard .card-body label {
  color: #222;
  font-size: 12px;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.24px;
  border-radius: 50px;
  border: 1px solid rgba(6, 167, 224, 0.11);
  background: #fff;
  padding: 5px 8px;
  position: absolute;
  bottom: -10px;
  right: 20px;
}

.usercard .quoteimg {
  position: absolute;
  top: -15px;
  left: -30px;
  width: 22px !important;
}

.usercard .quoteimg2 {
  position: absolute;
  bottom: 0;
  width: 22px !important;
  right: 0;
}
.white-star {
  background-color: #fff;
}
.star {
  padding-left: 20px;
}
.starblue {
  color: #06a7e0;
}

/* DownloadPage.css */

.downloadpage-container {
  background-color: #f8f8f8;
  padding: 108px 0;
}

.downloadpage-container .feature-page-content {
  margin-left: 0;
}

.Downloadheading p {
  color: var(--Gray-1, #333);
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: 45px;
  text-transform: capitalize;
}
.Downloadheading span {
  color: #06a7e0;
  font-size: 40px;
  font-style: normal;
  font-weight: 200;
  line-height: 45px;
  text-transform: capitalize;
}
.Downloadimg {
  max-width: 100%;
  height: auto;
  right: -15px;
  top: -14px;
  position: absolute;
}
.downloadpara {
  color: #06a7e0;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  border-left: 6px solid;
  padding-left: 25px;
}
.downloadparagrph {
  color: #555;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  text-transform: capitalize;
}

/* contactPage.css */

.contactusimg {
  max-width: 100%;
  height: auto;
  right: -15px;
  top: -14px;
  position: absolute;
}

.contactpage-container {
  background-color: #fff;
  padding: 110px 0;
}
.contacticontext {
  color: #222;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.icon {
  padding-top: 45px;
}
.contacticon {
  color: var(--Gray-1, #333);
  text-align: center;
  font-size: 33px;
  font-style: normal;
  font-weight: 700;
  line-height: 45px;
}
.contacticon span {
  color: #06a7e0;
  font-size: 33px;
  font-style: normal;
  font-weight: 300;
  line-height: 45px;
}

.contactpage .feature-page-content {
  margin-left: 0;
}
.conatactBluetext {
  color: #06a7e0;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.context {
  padding-left: 62px;
}
.context p {
  color: #8c8c8c;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.form {
  border-radius: 20px;
  background: #f8f8f8;
}
.form form {
  padding: 40px;
}
.form input {
  margin-bottom: 25px;
  border-radius: 10px;
  border: 1px solid rgba(40, 120, 168, 0.2);
  background: #fff;
  min-height: 50px;
}
.form label {
  padding-bottom: 10px;
  color: #0c0e11;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.submitbtn {
  background-color: #727272;
  color: #ffffff;
  padding: 10px;
  margin-top: 25px;
}

.owl-theme .owl-dots .owl-dot span {
  width: 28px !important;
  height: 4px !important;
  border-radius: 50px !important;
  background: #d9d9d9 !important;
  transition: all 0.3s !important;
}

.owl-theme .owl-dots .owl-dot.active span,
.owl-theme .owl-dots .owl-dot:hover span {
  background: #06a7e0 !important;
  width: 45px !important;
}

.feature-page .owl-theme .owl-dots .owl-dot.active span,
.feature-page .owl-theme .owl-dots .owl-dot:hover span {
  background: #263238 !important;
}

.feature-page .owl-carousel .item > .position-relative {
  margin: 0 0 0 2.5rem;
}

@media (max-width: 991px) {
  .fonttext {
    font-size: 30px;
  }
  .fonttext1 {
    font-size: 30px;
  }
  .featurelogotext {
    font-size: 35px;
  }
  .reviewicon,
  .abouticon,
  .abouticon span {
    font-size: 30px;
  }
  .reviewicon span {
    font-size: 30px;
  }
  .downloadparagrph {
    margin-bottom: 20px;
  }
  .icon {
    padding-top: 30px;
  }
  .form {
    margin-top: 20px;
  }
  .downloadpage-container .feature-page-content {
    margin-left: auto;
  }
}

@media (max-width: 767px) {
  .fonttext {
    font-size: 25px;
  }
  .fonttext1 {
    font-size: 25px;
  }
  .icon-container img {
    width: 120px;
  }
}

@media (max-width: 370px) {
  .reviewiconimg {
    right: 2px;
  }
  .Downloadimg {
    right: -10px;
  }
}

/* FooterPage.css */
.footer-container {
  background: #06a7e0;
  justify-content: center;
  align-items: center;
  position: relative;
  margin-top: 80px;
}

.image-container {
  left: 50%;
  position: absolute;
  top: -78px;
  transform: translateX(-50%);
  z-index: -1;
}
.image-container img {
  width: 100%;
}
.icon-container {
  text-align: center;
}

.icon-container img {
  margin-top: -10px;
  width: 240px;
}
.footertext {
  color: #fff;
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 36px 0 18px;
  display: flex;
  flex-wrap: wrap;
}

.footer-copyright {
  padding: 28px 0 22px;
  color: #fff;
  text-align: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  position: relative;
  border-top: 1px solid rgba(255, 255, 255, 0.233);
  margin-top: 35px;
}
.social-icons {
  position: relative;
  font-size: 40px;
}
.footerptag {
  border-right: 1px solid white;
  padding-right: 10px;
  padding-left: 10px;
}
.ptag {
  padding-left: 10px;
}
.back-to-top {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #06a7e0;
  color: #fff;
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  font-size: 20px;
  cursor: pointer;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background-color 0.3s ease;
  z-index: 999;
}

.back-to-top:hover {
  background-color: #0695e0;
}

@media (max-width: 767px) {
  .footertext {
    font-size: 18px;
  }
  .image-container {
    top: -60px;
  }
  .footer-copyright {
    font-size: 16px;
  }
}

/* Success and Faild Error page  */
.payment-card {
  background: white;
  padding: 60px;
  border-radius: 4px;
  box-shadow: 0 2px 3px #C8D0D8;
  display: inline-block;
  margin: 0 auto;
}
.payment-header{
  border-radius:200px; 
  height:200px; 
  width:200px; 
  background: #F8FAF5; 
  margin:0 auto;
}
.payment-faield-header-container{
  border-radius:200px; 
  height:200px; 
  width:200px; 
  background: #fff4f4; 
  margin:0 auto;
}
.check-mark-success{
  color: #9ABC66;
  font-size: 100px;
  line-height: 200px;
  margin-left:-15px;
}
.check-mark-faield{
  color: #ff0000;
  font-size: 100px;
  line-height: 200px;
  margin-left:-15px;
}
.payment-success-message{
  color: #404F5E;
  font-family: "Nunito Sans", "Helvetica Neue", sans-serif;
  font-size:20px;
  margin: 0;
}
.payment-success-header{
  color: #88B04B;
  font-family: "Nunito Sans", "Helvetica Neue", sans-serif;
  font-weight: 900;
  font-size: 40px;
  margin-bottom: 10px;
}
.payment-faield-header{
  color: #ff0000;
  font-family: "Nunito Sans", "Helvetica Neue", sans-serif;
  font-weight: 900;
  font-size: 40px;
  margin-bottom: 10px;
}
.payment-container{
  text-align: center;
  padding: 40px 0;
  background: #EBF0F5;
}